import React, { useEffect, useMemo, useState, useRef } from "react";
import UsersComponent from '../components/UsersComponent';
import { deleteUserService, getUsersPaginationService, getUsersTotalService, adminCreateUserService, adminCreateUserCarnetService, editUserService, getUsersService, getCarnetsService, createCarnetService } from 'services/user';
import { geyPaymentsMethodUser } from 'services/payments';
import { RiContactsBookLine } from "react-icons/ri";
import { create } from "lodash";

const Users = (props) => {
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState([]);
    const [usersFetching, setUsersFetching] = useState(false);
    const [totalUsersFetching, setTotalUsersFetching] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [deleteUserLoading, setDeleteUserLoading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isManageUserModalOpen, setIsManageModalUserOpen] = useState(false);
    const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
    const [isRegisteringUser, setIsRegisteringUser] = useState(false);
    const [isNewUserClubMember, setIsNewUserClubMember] = useState(false);
    const [isErrorModal, setIsErrorModal] = useState(false);
    const [errorModal, setErrorModal] = useState("");
    const [submitUserLoading, setSubmitUserLoading] = useState(false);
    const [paginationTotal, setPaginationTotal] = useState(0);
    const [paginationCurrent, setPaginationCurrent] = useState(1);
    const [paginationStep, setPaginationStep] = useState(10);
    const [paginationUrl, setPaginationUrl] = useState('/usuarios/');
    const [searchbarInput, setSearchbarInput] = useState('');
    const [searchbarResults, setSearchbarResults] = useState([]);
    const [searchbarShowing, setSearchbarShowing] = useState(false);
    const [sortConfiguration, setSortConfiguration] = useState({key: null, direction: null});
    const tableHeaderRef = useRef(null);

    useEffect(() => {
        getUsers();
        getTotalUsers();
    }, []);

    useEffect(() => {
        getAdditionalInfo(selectedUser);
        //getPaymentInfo(selectedUser);
        //getCarnetsInfo(selectedUser);
    }, [isManageUserModalOpen]);

    useEffect(() => {
        console.log("SELECTED USER", selectedUser);
    }, [selectedUser]);

    useEffect(() => {
        if(!isCreateUserModalOpen) {
            setIsErrorModal(false);
        }
    }, [isCreateUserModalOpen]);

    useMemo(() => {
        let tempSearchbar = [...searchbarResults];
        tempSearchbar.sort((a,b) => {
            if (a[sortConfiguration.key] < b[sortConfiguration.key]) {
                return sortConfiguration.direction === 'ascending' ? -1 : 1;
              }
              if (a[sortConfiguration.key] > b[sortConfiguration.key]) {
                return sortConfiguration.direction === 'ascending' ? 1 : -1;
              }
              return 0;
        });
        setSearchbarResults(tempSearchbar);
    }, [sortConfiguration]);


    const getUsers = async () => {
        setUsersFetching(true);

        try {
            //BEGIN Pagination

            const totalUsersData = await getUsersTotalService(); //CHANGE when copying

            let totalData = parseInt(totalUsersData.count);

            //No payments, function should exit to save up resources.

            if(totalData==0) {
                setUsersFetching(false); //CHANGE when copying
                return;
            }

            setPaginationTotal(totalData);

            //Check if pagenumber is ok, elsewhere it returns value to '1'

            let pageNumber = parseInt(props.location.pathname.split(paginationUrl)[1]);

            if(pageNumber > 1) {
                
                if(pageNumber*paginationStep-totalData >= paginationStep) pageNumber = 1;

            }

            if(pageNumber <= 0) pageNumber = 1;

            if(isNaN(pageNumber)) pageNumber = 1;

            setPaginationCurrent(pageNumber);

            let paginationOptions = {
                limit: paginationStep,
                skip: (pageNumber-1)*paginationStep,
            }


            //const paymentsRawData = await getUsersService();
            const data = await getUsersPaginationService(paginationOptions);

            setUsers(data);
            setSearchbarResults(data);
        } catch (err) {
            console.log(err);
        }

        setUsersFetching(false);
    }

    const getTotalUsers = async () => {
        setTotalUsersFetching(true);

        try {

            const data = await getUsersService();

            setTotalUsers(data);
        } catch (err) {
            console.log(err);
        }

        setTotalUsersFetching(false);
    }


    const getAdditionalInfo = async (selectedUser) => {

        if(selectedUser.paymentInfo===undefined)

        try {

            let additionalInfo = {};

            let payment = await geyPaymentsMethodUser(selectedUser.profileId);

            if(payment.length>0) {
                additionalInfo.paymentInfo = payment;
                //setSelectedUser({...selectedUser, paymentInfo: payment});
            }

            let carnets = await getCarnetsService(selectedUser.profileId);

            if(carnets.length>0) {
                additionalInfo.carnets = carnets
                //setSelectedUser({...selectedUser, carnets: carnets});
            }

            setSelectedUser({...selectedUser, ...additionalInfo});

        } catch (err) {
            console.log(err);
        }

    }


    /*const getPaymentInfo = async (selectedUser) => {

        if(selectedUser.paymentInfo===undefined)

        try {

            let payment = await geyPaymentsMethodUser(selectedUser.profileId);

            if(payment.length>0) setSelectedUser({...selectedUser, paymentInfo: payment});

        } catch (err) {
            console.log(err);
        }

    }

    const getCarnetsInfo = async (selectedUser) => {

        if(selectedUser.carnets===undefined) {

            try {
                
                let carnets = await getCarnetsService(selectedUser.profileId);

                console.log("CARNETS", carnets, carnets.type);

                if(carnets.length>0) {
                    setSelectedUser({...selectedUser, carnets: carnets});
                }

            } catch (err) {
                console.log(err);
            }
        }

    }*/

    const setCarnetUser = async(data, selectedUser) => {
        try {

            let carnet = await createCarnetService(data, selectedUser);

            console.log("SUCCESS ", carnet);

        } catch (err) {
            console.log(err);
        }
    }

    const submitUser = async (formValues, userId) => {
        setSubmitUserLoading(true);

        /*let nif = formValues.nif;
        delete formValues.nif;*/

        let userData = {
            ...formValues,
            profileId: userId,
        }

        /*userData.extraData.nif = nif;*/

        try {
            if (userId) { //edit an existing course

                const apiAllowedFields = ["name", "surname", "nif", "profileId", "address"];

                for(var key of Object.keys(userData)) {
                    if(!apiAllowedFields.includes(key)) delete userData[key];
                }


                const editservice = await editUserService(userData, userId);
                await getUsers();
            }
            /*else {
                const data = await createUserService(userData);
                setUsers([...users, data]);
            }*/
            setIsManageModalUserOpen(false);
        } catch (err) {
            console.log(err)
        }
        finally {
            setSubmitUserLoading(false);
        }
    }

    const addUser = async (formValues) => {

        if(isRegisteringUser) {
            return;
        } 

        setIsRegisteringUser(true);

        let userData = {
            name: formValues.name,
            surname: formValues.surname,
            email: formValues.email,
            password: formValues.password,
            phone: formValues.phoneprefix + formValues.phone,
            phoneCountryCode: formValues.phoneprefix
        }

        if(typeof formValues.postalCode !== "undefined" && formValues.postalCode.length>0) {
            userData.postalCode = formValues.postalCode
        }

        if(typeof formValues.address !== "undefined" && formValues.address.length>0) {
            userData.address = formValues.address
        }


        try {
            const data = await adminCreateUserService(userData);

            if(isNewUserClubMember) {
                try {
                    const club = await adminCreateUserCarnetService(data.profileId, {
                        type: "club",
                        extraData: {
                            nif: formValues.nif
                        }
                    });


                } catch(err) {
                    console.log(err);

                } 
            }

            //setUsers([...users, data]);
            await getUsers();
            setIsCreateUserModalOpen(false);

        } catch(err) {
            console.log(err);
            //SHOW BACKEND MESSAGE INSTEAD
            setErrorModal("Ha ocurrido un error. Es probable que este usuario ya exista en el registro de 'Usuarios'.")
            setIsErrorModal(true);
        } finally {

            setIsRegisteringUser(false);
        }

    }

    const deleteUser = async (userId) => {
        setDeleteUserLoading(true);

        try {
            await deleteUserService(userId);
            await getUsers();
            setIsDeleteModalOpen(false);
        } catch (err) {
            console.log(err);
        }
        finally {
            setDeleteUserLoading(false);
        }
    }

    return <UsersComponent
        users={users}
        setUsers={setUsers}
        usersFetching={usersFetching}
        submitUser={submitUser}
        addUser={addUser}
        deleteUser={deleteUser}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        deleteUserLoading={deleteUserLoading}
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        isManageUserModalOpen={isManageUserModalOpen}
        setIsManageModalUserOpen={setIsManageModalUserOpen}
        setCarnetUser={setCarnetUser}
        submitUserLoading={submitUserLoading}
        paginationTotal={paginationTotal}
        paginationCurrent={paginationCurrent}
        paginationStep={paginationStep}
        paginationUrl={paginationUrl}
        searchbarResults={searchbarResults}
        setSearchbarResults={setSearchbarResults}
        searchbarInput={searchbarInput}
        setSearchbarInput={setSearchbarInput}
        setPaginationStep={setPaginationStep}
        totalUsers={totalUsers}
        searchbarShowing={searchbarShowing}
        setSearchbarShowing={setSearchbarShowing}
        isCreateUserModalOpen={isCreateUserModalOpen}
        setIsCreateUserModalOpen={setIsCreateUserModalOpen}
        isNewUserClubMember={isNewUserClubMember}
        setIsNewUserClubMember={setIsNewUserClubMember}
        sortConfiguration={sortConfiguration}
        setSortConfiguration={setSortConfiguration}
        tableHeaderRef={tableHeaderRef}
        isRegisteringUser={isRegisteringUser}
        errorModal={errorModal}
        isErrorModal={isErrorModal}
    />
}

export default Users;
